// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: 'How does the free trial work?',
    answer:
      "Anyone can try the DAGWorks Team plan free for 14 days. At the end of your trial you can choose to continue on a paid plan. If you do nothing, you'll be downgraded to the free Community plan and lose access to premium features.",
  },

  {
    question: 'What is a project?',
    answer:
      'A project represents some unit of work that you want to perform and manage. E.g. LTV model, or portfolio construction. This should map to a Hamilton dataflow in code.',
  },

  {
    question: 'What is a dataflow vs a pipeline?',
    answer:
      'With Hamilton one can define many pipelines with a single dataflow. For example, you can run multiple feature pipelines, e.g. US clients, vs UK clients, using the same Hamilton dataflow. You can think of a Hamilton dataflow as defining the superset of possible pipelines. Though, in simple cases, a dataflow will map 1-1 with a pipeline.',
  },

  {
    question: 'What is an execution?',
    answer:
      'An execution is a specific run of a Hamilton dataflow with appropriate parameterizations. You execute the Hamilton dataflow on your own infrastructure, using the DAGWorks Driver, and that information is logged to the DAGWorks platform.',
  },

  {
    question: 'What is the lineage view?',
    answer: 'Lineage view is an interactive visualization of how code and artifacts connect.',
  },

  {
    question: 'What is the catalog view?',
    answer:
      'The catalog view allows you to see and search for code and artifacts in a tabular view.',
  },

  {
    question: 'What is code observability?',
    answer:
      'Anytime a Hamilton dataflow is run, the code that defines it is tracked. This enables one to easily compare and understand changes that happen, irrespective if they’re tracked by a version control system or not, e.g. in someone’s jupyter notebook.',
  },

  {
    question: 'What is data observability?',
    answer:
      'Anytime a Hamilton dataflow is run, the outputs of functions can be introspected for summary statistics. This enables one to easily compare and understand changes that happen between pipeline runs, as well as build alerts on top of them. See documentation for currently supported types.',
  },

  {
    question: 'What does the * mean?',
    answer:
      'It means that these features are currently in beta and may not be available to all users. Contact the team to get access.',
  },

  {
    question: 'What is a Hamilton dataflow version?',
    answer:
      'Every time an execution occurs, we determine if a new version of the dataflow has been used. This changes based on the actual code that was used and subsequently we can assign a new version in that case.',
  },

  {
    question: 'what is an observability alert?',
    answer:
      'It is a single alert defined on the output of a particular function, within a project, that utilizes the data observability functionality.',
  },

  {
    question: 'what is CI impact integration?',
    answer:
      'CI or continuous integration is a common step that is run when code is changed before releasing to production. CI impact integration is an integration step that can surface impacts and changes for a particular code change, like impacted teams, models, etc.',
  },

  {
    question: 'What is reporting on lineage, python dependencies, and artifacts?',
    answer:
      'Enterprises often have governance, and security teams that want insight into how and where data is used. The DAGWorks platform offers customizable reporting capabilities for these teams.',
  },

  {
    question: 'What is a shareable catalog?',
    answer:
      "The catalog can be viewed with special 'view seats', and exportable/sync-able with your data catalog provider. At this time, only open source catalogs are supported.",
  },

  {
    question: 'What is a customizable report on compliance?',
    answer:
      'Compliance reporting is customizable to help ensure that one can easily show adherence to internal and external policies with data usage.',
  },

  {
    question: 'What is community slack support?',
    answer:
      'Community slack support happens in our dagworks platform slack community. It is best effort support where one can ask questions and have the community help answer questions, answerable by DAGWorks team members based on bandwidth.',
  },

  {
    question: 'What is a dedicated slack support channel and its SLA?',
    answer:
      'SLA for slack dedicated slack support is <2 hours during business hours 8am-8pm PDT. Users will be invited to a dedicated slack connect channel; if slack is not used, users will be invited to the community channel with a private room.',
  },

  {
    question: 'What SSO providers do you support?',
    answer: 'We support all major SSO SAML providers for the enterprise tier.',
  },

  {
    question: 'What are granular function execution cost estimates?',
    answer:
      'Where applicable, it is possible to estimate computation costs, and identify the most expensive functions in a dataflow.',
  },

  // More questions...
]

export const FAQs = () => {
  return (
    <div className='bg-white'>
      <div className='mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-20'>
        <div className='mx-auto max-w-4xl divide-y divide-gray-900/10'>
          <h2 className='text-2xl font-bold leading-10 tracking-tight text-gray-900'>
            DAGWorks Platform Frequently Asked Questions
          </h2>
          <dl className='mt-10 space-y-6 divide-y divide-gray-900/10'>
            {faqs.map((faq) => (
              <Disclosure as='div' key={faq.question} className='pt-6'>
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className='flex w-full items-start justify-between text-left text-gray-900'>
                        <span className='text-base font-semibold leading-7'>{faq.question}</span>
                        <span className='ml-6 flex h-7 items-center'>
                          {open ? (
                            <MinusSmallIcon className='h-6 w-6' aria-hidden='true' />
                          ) : (
                            <PlusSmallIcon className='h-6 w-6' aria-hidden='true' />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as='dd' className='mt-2 pr-12'>
                      <p className='text-base leading-7 text-gray-600'>{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export const Pricing = () => {
  return (
    <div className='w-full h-full bg-white pt-20'>
      <div className='flex flex-col'>
        <h2 className='text-3xl text-center font-bold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight'>
          Hosted Hamilton UI on DAGWorks Pricing
        </h2>
        <h2 className='text-2xl text-center font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>
          Everybody gets a 14-day trial at the Team level.
        </h2>
        <stripe-pricing-table
          pricing-table-id='prctbl_1NpyJkKgwHqI5hVRVW8h9pZh'
          publishable-key='pk_live_51N3QnVKgwHqI5hVRLyrB4DOew9XDzhTAXQJUzIHp8oCP4CtFxWED68eMs9vDd2jyvwIMZGFhOLIuTxaam1KNIGNw00OXaDFKty'
        ></stripe-pricing-table>
        <h2 className='text-3xl text-center font-bold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight'>
          Burr Cloud Pricing - Coming Soon.
        </h2>
        <FAQs></FAQs>
      </div>
    </div>
  )
}
