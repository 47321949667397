export const Home = () => {
  return (
    <main className=''>
      <div className='flex flex-col bg-white py-4 px-12 items-center justify-center'>
        <div className='py-4'>
          <div className='py-8'>
            <div className='text-7xl text-black justify-center font-semibold sm:text-7xl'>
              <a href='https://github.com/dagworks-inc/hamilton'>Hamilton</a> |{' '}
              <a href='https://github.com/dagworks-inc/burr'>Burr</a>
            </div>
            <div className='lead-xl font-light pt-10 text-2xl sm:text-2xl'>
              <p>Build Reliable AI with Hamilton & Burr.</p>
              <p>Deliver value faster and integrate with observability tools in a single line.</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col px-20 md:px-10 md:flex-row items-center justify-center gap-6'>
          <a href='/hamilton'>
            <div className=''>
              <p className='align-center lead-xl font-light pt-10 text-2xl sm:text-2xl'>
                Building RAG/ML pipelines? Start with Hamilton.
              </p>
              <img
                src='/lineage_view.png'
                width='500px'
                alt='Featured Image 1'
                className='rounded-t-xl hover:scale-105'
              />
              <div className='px-9 pt-10 pb-14 bg-dwred/90 hover:bg-dwred rounded-b-lg'>
                <div className='text-white space-y-4'>
                  <h3 className='text-xl font-bold lead-xl bold'>Hamilton + Hosted Hamilton UI</h3>
                  <div className='text-lg font-light'>
                    <p>Iterate on Python pipelines 4x faster with Hamilton.</p>
                    <p>Use the self-hosted or SaaS Hamilton UI for:</p>
                  </div>
                </div>
                <div className='flex justify-between pt-8'>
                  <ul className='flex flex-col gap-y-2.5'>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>Provenance & Lineage</span>
                    </li>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>Observability</span>
                    </li>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>Catalog</span>
                    </li>
                  </ul>
                  <div className='flex flex-col justify-end'>
                    <a
                      href='/hamilton'
                      className='py-3 px-4 bg-white text-primary-200 paragraph-m rounded-md'
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
          <a href='/burr'>
            <div className=''>
              <p className='lead-xl font-light pt-10 text-2xl sm:text-2xl'>
                Doing GenAI? Start with Burr.
              </p>
              <img
                src='/burr_ui.gif'
                alt='Featured Image 1'
                className='rounded-t-xl hover:scale-105'
                width='500px'
              />
              <div className='px-9 pt-10 pb-14 bg-dwlightblue/90 hover:bg-dwlightblue rounded-b-lg'>
                <div className='text-white space-y-4'>
                  <h3 className='text-xl font-bold lead-xl bold'>Burr + Burr Cloud</h3>
                  <div className='text-lg font-light'>
                    <p>Build & debug RAG + Agentic applications faster. </p>
                    <p>Host yourself or use Burr Cloud:</p>
                  </div>
                </div>
                <div className='flex justify-between pt-8'>
                  <ul className='flex flex-col gap-y-2.5'>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>Hosted Execution</span>
                    </li>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>State Management & Persistence</span>
                    </li>
                    <li className='flex space-x-3 text-white'>
                      <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                      <span className='paragraph-l font-bold'>Observability</span>
                    </li>
                  </ul>
                  <div className='flex flex-col justify-end'>
                    <a
                      href='/burr'
                      className='py-3 px-4 bg-white text-primary-200 paragraph-m  rounded-md'
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className='mx-auto max-w-7xl justify-center px-6 lg:px-8 flex pt-20 flex-wrap gap-10'>
        {/* <div className='justify-center flex pb-5'> */}
        <h1 className='font-semibold text-gray-400 w-max'>Trusted by</h1>
        {/* </div> */}
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/f33.png'
          alt='F33'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/ornl.svg'
          alt='ORNL'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/Variant=Flame-Black@4x.png'
          alt='flectere'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/equipmentshare.png'
          alt='EquipmentShare'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/naro_logo.png'
          alt='Naro'
          width={158}
          height={48}
        />
        {/* <div className='justify-center flex pb-5'> */}
        <h1 className='font-semibold text-gray-400 w-max'>Backed by</h1>
        {/* </div> */}
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/ycombinator.svg'
          alt='yc'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/firestreak.png'
          alt='firestreak'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/buildersfund.png'
          alt='buildersfund'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/startx.png'
          alt='startx'
          width={158}
          height={48}
        />
        {/* </div> */}
      </div>
    </main>
  )
}
