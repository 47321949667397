import { features } from 'process'
import { ReactNode, useEffect, useState } from 'react'
import { AiOutlineFunction, AiOutlineLineChart } from 'react-icons/ai'
import { BsEyeglasses, BsPlayCircle } from 'react-icons/bs'
import { IoCode, IoGitNetwork, IoTerminal } from 'react-icons/io5'
import CodeExample, { ExplainOpenSource } from './CodeExample'
import ReactPlayer from 'react-player'
import { SwapOutDriver } from './SwapOutDriver'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const primaryFeatures = [
  {
    name: 'Resolve issues faster',
    description:
      'Use lineage to quickly understand how your data, your code, and your models connect. ' +
      'Then understand how your pipeline and data change over time with pipeline comparison features.',
    icon: IoGitNetwork,
    image: '/lineage_to_compare.gif',
  },
  {
    name: 'Reduce onboarding time',
    description:
      "Quickly understand each others' pipelines with self-populating lineage, code, catalog, and execution views.",
    icon: IoTerminal,
    image: '/onboarding.gif',
  },
  {
    name: 'Consolidate MLOps tooling',
    description:
      'No need to manage separate services and deal with multiple UIs for your lineage, catalog, data quality, and observability needs. ' +
      "It's all in one place with the Hosted Hamilton UI on DAGWorks.",
    icon: IoCode,
    image: '/Landing_Page_MLOps_consolidation.png',
  },
  {
    name: 'Fulfill compliance needs',
    description:
      'The Hamilton UI helps you track lineage of code and data with no extra work. You can easily audit how data flows through your pipelines and how it is used.',
    icon: IoCode,
    image: '/lineage_view.png',
  },
  {
    name: 'Gain visibility everywhere',
    description:
      'The Hamilton UI is a lightweight integration that runs wherever you need it to. ' +
      'Use with your own orchestration systems, data warehouses, notebooks, web & streaming services, and modeling libraries.',
    icon: BsEyeglasses,
    display: () => {
      return <ExplainOpenSource />
    },
  },
]
const productInstructions = [
  {
    // name: 'Organize your code with Hamilton',
    name: 'Build on Open Source',
    description:
      "You're not locked into anything. Hamilton & the Hamilton UI are fully open source tools. " +
      'Hamilton is compatible with your current python code, easy to get started with, and straightforward to use. ' +
      'You can try the Hamilton UI locally first, and then switch to using the hosted Hamilton UI on DAGWorks.',
    href: 'https://www.tryhamilton.dev',
    icon: AiOutlineFunction,
    image: '/hamilton.png',
  },
  {
    name: 'Add in our client',
    description:
      'With a one-line addition, you gain the full power of Hamilton & the Hamilton UI. Run as you normally would, and get full visibility into everything that occurred.',
    href: 'https://docs.dagworks.io',
    icon: BsPlayCircle,
    display: () => {
      return <SwapOutDriver />
    },
  },
  {
    name: 'Visualize with the UI',
    description:
      'Use the Hosted Hamilton UI to document your data transformation, debug your pipelines, and understand how they change over time.',
    href: 'https://app.dagworks.io',
    icon: AiOutlineLineChart,
    image: '/lineage_diff_view.png',
  },
]
const featuredTestimonial = {
  body: 'DAGWorks/Hamilton is our top bet for implementing the sophisticated aggregation and disaggregation capabilities that are crucial to the high-stakes decision making workflows we are designing. It truly enables us to think in [directed acyclic] graphs, which enables us to build our vision robustly and iterate with confidence.',
  author: {
    name: 'Gireesh Ramji',
    handle: '',
    imageUrl: 'customers/Gireesh_original_BW.png',
    logoUrl: '/customers/Variant=Flame-Black@4x.png',
  },
}
const testimonials = [
  [
    [
      {
        body: 'DAGWorks/Hamilton is our top bet for implementing the sophisticated aggregation and disaggregation capabilities that are crucial to the high-stakes decision making workflows we are designing. It truly enables us to think in [directed acyclic] graphs, which enables us to build our vision robustly and iterate with confidence.',
        author: {
          name: 'Gireesh Ramji',
          handle: 'lesliealexander',
          imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.',
        author: {
          name: 'Lindsay Walton',
          handle: 'lindsaywalton',
          imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Voluptas quos itaque ipsam in voluptatem est. Iste eos blanditiis repudiandae. Earum deserunt enim molestiae ipsum perferendis recusandae saepe corrupti.',
        author: {
          name: 'Tom Cook',
          handle: 'tomcook',
          imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Molestias ea earum quos nostrum doloremque sed. Quaerat quasi aut velit incidunt excepturi rerum voluptatem minus harum.',
        author: {
          name: 'Leonard Krasner',
          handle: 'leonardkrasner',
          imageUrl:
            'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
]

export const PaneWithInfo = (props: {
  name: string
  href?: string | undefined
  description: string
  image?: string | undefined
  index: number
  display?: ((props: any) => JSX.Element) | undefined
}) => {
  const color = ['from-dwlightblue/30', 'from-green-500/30'][props.index % 2]
  return (
    <div
      key={props.name}
      className={`w-full flex flex-col text-md sm:text-lg gap-10 lg:gap-10 ${
        props.index % 2 == 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
      }  lg:justify-between items-center`}
    >
      <div className='flex flex-col max-w-full lg:max-w-md'>
        <hr className={`w-72 h-1 my-4 bg-gradient-to-r ${color} to-transparent border-0 rounded`} />
        {/* <hr className='w-32 h-1 my-3 bg-dwlightblue/80 border-0 rounded md:my-3' /> */}
        <dt className='text-3xl flex items-center gap-x-3 font-bold leading-7 text-gray-900'>
          {/* <feature.icon
                      className='h-5 w-5 flex-none text-dwdarkblue'
                      aria-hidden='true'
                    /> */}
          {props.name}
        </dt>
        <dd className='mt-4 flex flex-auto flex-col leading-7 text-gray-600 sm:text-lg text-md'>
          <p className='flex-auto'>{props.description}</p>
          {props.href ? (
            <p className='mt-6'>
              <a
                href={props.href}
                className='text-md sm:text-lg font-semibold leading-6 text-dwdarkblue'
              >
                Learn more <span aria-hidden='true'>→</span>
              </a>
            </p>
          ) : (
            <></>
          )}
        </dd>
      </div>
      {props.image !== undefined ? (
        <img src={props.image} className='h-auto max-w-full lg:max-w-3xl max-h-lg'></img>
      ) : props.display !== undefined ? (
        <div className='pr-5'>
          <props.display />
        </div>
      ) : (
        <></>
      )}
    </div>
  )
}
const pipelineFocus = ['*', 'RAG', 'LLM', 'data', 'ML']
const teamFocus = ['ML', 'AI', 'DE', 'DS', 'ENG']
const teamRotateTime = 3000
export const Hamilton = () => {
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState<number>(0)
  const [currentPipelineFocusIndex, setCurrentPipelineFocusIndex] = useState<number>(0)
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFeatureIndex((currentFeatureIndex + 1) % teamFocus.length)
    }, teamRotateTime)
    return () => clearInterval(interval)
  })
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPipelineFocusIndex((currentPipelineFocusIndex + 1) % pipelineFocus.length)
    }, teamRotateTime)
    return () => clearInterval(interval)
  })

  const currentTeamFocus = teamFocus[currentFeatureIndex]
  const currentPipelineFocus = pipelineFocus[currentPipelineFocusIndex]

  return (
    <main>
      {/* Hero section */}
      <div className='flex justify-center'>
        <h1 className='pt-20 text-3xl font-semibold tracking-tight text-gray-900 sm:text-6xl pb-10 '>
          Hosted Hamilton UI
        </h1>
      </div>
      <div className='mx-auto max-w-7xl px-6 pb-24 sm:pb-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:pb-40'>
        <div className='mx-auto max-w-xl lg:mx-0 lg:flex-auto'>
          <h1 className='mt-10 max-w-xl text-2xl font-bold tracking-tight text-gray-900 sm:text-5xl'>
            Supercharge Hamilton &amp;
            <br />
            build better <span className='text-dwlightblue'>{currentPipelineFocus}</span> pipelines.
            Faster.
          </h1>
          <p className='mt-6 sm:text-lg text-md leading-8 text-gray-600'>
            Accelerate MLOps/LLMOps capabilities, remove code maintenance nightmares, and
            automatically capture the connection between data and models. Hamilton & the Hamilton UI
            is built for the forward-thinking{' '}
            <span className='font-medium'>{currentTeamFocus}</span> teams.
          </p>
          <div className='mt-10 flex sm:flex-row flex-col items-center gap-x-6 gap-y-2'>
            <a
              href='https://auth.app.dagworks.io/signup'
              className='rounded-md bg-dwred px-3.5 py-2.5 text-md sm:text-lg font-semibold text-white shadow-sm hover:bg-dwred/80 focus-visible:outline
              focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dwdarkblue'
            >
              Get started for free
            </a>
            <a
              href='https://calendly.com/stefan-dagworks/15-minute-intro-call-with-stefan'
              className='text-md sm:text-lg font-semibold leading-6 text-gray-900 hover:text-dwlightblue'
              target='_blank'
              rel='noreferrer'
            >
              Talk to Us <span aria-hidden='true'>→</span>
            </a>
          </div>
        </div>
        <div className='mt-16 sm:mt-24 lg:mt-0 lg:flex-shrink-0 lg:flex-grow text-center flex justify-center align-middle overflow-hidden'>
          {/* <img src='/feature_2.png' className='opacity-80 max-w-full md:max-w-3xl' /> */}
          <ReactPlayer
            url='https://youtu.be/CxjQEn6VYqw'
            height='450px'
            width='720px'
            playsinline
          />
        </div>
      </div>
      {/* Logo cloud */}
      {/* <div className='hidden'> */}
      <div className='mx-auto max-w-7xl  justify-center px-6 lg:px-8 flex flex-wrap gap-10'>
        {/* <div className='justify-center flex pb-5'> */}
        <h1 className='font-semibold text-gray-400 w-max'>Trusted by</h1>
        {/* </div> */}
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/f33.png'
          alt='F33'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/ornl.svg'
          alt='ORNL'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/Variant=Flame-Black@4x.png'
          alt='flectere'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/equipmentshare.png'
          alt='EquipmentShare'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/customers/naro_logo.png'
          alt='Naro'
          width={158}
          height={48}
        />
        {/* <div className='justify-center flex pb-5'> */}
        <h1 className='font-semibold text-gray-400 w-max'>Backed by</h1>
        {/* </div> */}
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/ycombinator.svg'
          alt='yc'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/firestreak.png'
          alt='firestreak'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/buildersfund.png'
          alt='buildersfund'
          width={158}
          height={48}
        />
        <img
          className='col-span-2 max-h-9 object-contain lg:col-span-1'
          src='/investors/startx.png'
          alt='startx'
          width={158}
          height={48}
        />
        {/* </div> */}
      </div>

      {/* Feature section */}
      {/* <div className='mx-auto mt-32 max-w-7xl sm:mt-56 sm:px-6 lg:px-8'>
        <div className='relative isolate overflow-hidden bg-gray-800 px-6 py-20 sm:rounded-3xl sm:px-10 sm:py-24 lg:py-24 xl:px-24'>
          <div className='mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center lg:gap-y-0'>
            <div className='lg:row-start-2 lg:max-w-md'>
              <h2 className='text-3xl font-bold tracking-tight text-white sm:text-4xl'>
                Your unifying platform for data and ML.
                <br />
              </h2>
              <p className='mt-6 text-lg leading-8 text-gray-300'>
                Streamline collaboration, creation, and maintenance of data and machine learning
                pipelines, all on top of your existing infrastructure.
              </p>
            </div>
            <img
              src={primaryFeatures[currentFeatureIndex].image}
              // src='https://tailwindui.com/img/component-images/dark-project-app-screenshot.png'
              alt='Product screenshot'
              className='relative -z-20 min-w-full max-w-xl rounded-xl shadow-xl ring-1 ring-white/10 lg:row-span-4 lg:max-w-none opacity-90'
              height={'600'}
              // width={"100"}
            />
            <div className='max-w-xl lg:row-start-3 lg:mt-10 lg:max-w-md lg:border-t lg:border-white/10 lg:pt-10'>
              <dl className='max-w-xl space-y-8 text-base leading-7 text-gray-300 lg:max-w-none'>
                {primaryFeatures.map((feature, i) => {
                  const isSelected = currentFeatureIndex === i
                  return (
                    <div
                      className={`relative cursor-pointer ${
                        isSelected ? 'text-white' : 'text-gray-400  hover:text-gray-300'
                      }`}
                      key={feature.name}
                      onClick={() => {
                        setCurrentFeatureIndex(i)
                      }}
                    >
                      <dt className={`ml-9 inline-block font-semibold`}>
                        <feature.icon
                          className='absolute left-1 top-1 h-5 w-5'
                          aria-hidden='true'
                        />
                        {feature.name}
                      </dt>{' '}
                      <dd className='inline'>{feature.description}</dd>
                    </div>
                  )
                })}
              </dl>
            </div>
          </div>
          <div
            className='pointer-events-none absolute left-12 top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-3xl lg:bottom-[-12rem] lg:top-auto lg:translate-y-0 lg:transform-gpu'
            aria-hidden='true'
          >
            <div
              className='aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-dwred to-dwlightblue opacity-25'
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
        </div>
      </div> */}

      <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center flex flex-col gap-10'>
          {/* <h2 className='text-base font-semibold leading-7 text-dwdarkblue'>Deploy faster</h2> */}
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Your incrementally adoptable single tool to express & introspect <br />
            data, ML, &amp; LLM pipelines.
          </p>
          <p className='mt-6 text-md sm:text-lg leading-8 text-gray-600'>
            Streamline collaboration, creation, and maintenance of data, machine learning, and LLM
            pipelines, all on top of your existing infrastructure.
          </p>
        </div>
        <div className='mx-auto max-w-7xl  justify-center px-6 lg:px-8 flex flex-wrap gap-10 pt-10'>
          {/* <div className='justify-center flex pb-5'> */}
          <h1 className='font-semibold text-gray-400 w-max'>Works with:</h1>
          {/* </div> */}
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/AirflowLogo.png'
            alt='airflow'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/dagster-primary-horizontal.png'
            alt='dagster'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/Databricks_Logo.png'
            alt='databricks'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/dbt.png'
            alt='dbt'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/fastapi.png'
            alt='fastapi'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/flask.png'
            alt='flask'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/flyte.png'
            alt='flyte'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/jupyter.png'
            alt='jupyter'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/metaflow.png'
            alt='metaflow'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/modal.svg'
            alt='modal'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/prefect.svg'
            alt='prefect'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/Snowflake_Logo.png'
            alt='snowflake'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/sagemaker.png'
            alt='sagemaker'
            width={158}
            height={48}
          />
          <img
            className='col-span-1 max-h-9 object-contain lg:col-span-1'
            src='/integrations/vertex.png'
            alt='vertex'
            width={158}
            height={48}
          />
        </div>
        <div className='mx-auto mt-16 sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='gap-x-8 gap-y-32 flex flex-col'>
            {primaryFeatures.map((feature, i) => {
              return <PaneWithInfo {...feature} index={i} key={'instruction' + i} />

              //   <div
              //     key={feature.name}
              //     className={`w-full flex flex-col text-lg gap-10 lg:gap-10 ${
              //       i % 2 == 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
              //     }  lg:justify-between items-center`}
              //   >
              //     <div className='flex flex-col max-w-full lg:max-w-md'>
              //       <dt className='text-3xl flex items-center gap-x-3 font-bold leading-7 text-gray-900'>
              //         {/* <feature.icon
              //           className='h-5 w-5 flex-none text-dwdarkblue'
              //           aria-hidden='true'
              //         /> */}
              //         {feature.name}
              //       </dt>
              //       <dd className='mt-4 flex flex-auto flex-col leading-7 text-gray-600'>
              //         <p className='flex-auto'>{feature.description}</p>
              //         {/* <p className='mt-6'>
              //           <a
              //             href={feature.href}
              //             className='text-sm font-semibold leading-6 text-dwdarkblue'
              //           >
              //             Learn more <span aria-hidden='true'>→</span>
              //           </a>
              //         </p> */}
              //       </dd>
              //     </div>
              //     <img src={feature.image} className='h-auto max-w-full lg:max-w-3xl max-h-lg'></img>
              //   </div>
            })}
          </dl>
        </div>
      </div>

      {/* Feature section */}
      <div className='mx-auto mt-32 max-w-7xl px-6 sm:mt-40 lg:px-8'>
        <div className='mx-auto max-w-2xl lg:text-center flex flex-col gap-10 '>
          {/* <h2 className='text-base font-semibold leading-7 text-dwdarkblue'>Deploy faster</h2> */}
          <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>
            Integrate with ease
          </p>
          <p className='mt-6 text-md sm:text-lg leading-8 text-gray-600'>
            Hamilton runs everywhere Python does. Write Hamilton code, and add one line to gain the
            full power of the Hamilton UI on DAGWorks. No data source, modeling library, or
            deployment infrastructure is incompatible.
          </p>
        </div>
        <div className='flex justify-center flex-row pt-2'>
          <img src='/product_experience.svg' className='opacity-90  lg:max-w-3xl max-w-full'></img>
        </div>
        <div className='mx-auto mt-16 sm:mt-20 lg:mt-24 lg:max-w-none'>
          <dl className='gap-x-8 gap-y-32 flex flex-col'>
            {productInstructions.map((feature, i) => {
              return <PaneWithInfo {...feature} index={i} key={'instruction' + i} />
              //   const color = ['from-dwlightblue/40', 'from-dwdarkblue/40', 'from-dwred/40'][i % 3]
              //   return (
              //     <div
              //       key={feature.name}
              //       className={`w-full flex flex-col text-lg gap-10 lg:gap-10 ${
              //         i % 2 == 0 ? 'lg:flex-row' : 'lg:flex-row-reverse'
              //       }  lg:justify-between items-center`}
              //     >
              //       <div className='flex flex-col max-w-full lg:max-w-md'>
              //         <hr
              //           className={`w-64 h-1 my-4 bg-gradient-to-r ${color} to-transparent border-0 rounded`}
              //         />
              //         {/* <hr className='w-32 h-1 my-3 bg-dwlightblue/80 border-0 rounded md:my-3' /> */}
              //         <dt className='text-3xl flex items-center gap-x-3 font-bold leading-7 text-gray-900'>
              //           {/* <feature.icon
              //           className='h-5 w-5 flex-none text-dwdarkblue'
              //           aria-hidden='true'
              //         /> */}
              //           {feature.name}
              //         </dt>
              //         <dd className='mt-4 flex flex-auto flex-col leading-7 text-gray-600'>
              //           <p className='flex-auto'>{feature.description}</p>
              //           <p className='mt-6'>
              //             <a
              //               href={feature.href}
              //               className='text-sm font-semibold leading-6 text-dwdarkblue'
              //             >
              //               Learn more <span aria-hidden='true'>→</span>
              //             </a>
              //           </p>
              //         </dd>
              //       </div>
              //       <img
              //         src={feature.image}
              //         className='h-auto max-w-full lg:max-w-3xl max-h-lg'
              //       ></img>
              //     </div>
              //   )
            })}
          </dl>
        </div>
      </div>

      {/* Testimonials section */}
      <div className='relative isolate mt-15 sm:mt-10 sm:pt-8'>
        <svg
          className='absolute inset-0 -z-10 hidden h-full w-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='55d3d46d-692e-45f2-becd-d8bdc9344f45'
              width={200}
              height={200}
              x='50%'
              y={0}
              patternUnits='userSpaceOnUse'
            >
              <path d='M.5 200V.5H200' fill='none' />
            </pattern>
          </defs>
          <svg x='50%' y={0} className='overflow-visible fill-gray-50'>
            <path
              d='M-200.5 0h201v201h-201Z M599.5 0h201v201h-201Z M399.5 400h201v201h-201Z M-400.5 600h201v201h-201Z'
              strokeWidth={0}
            />
          </svg>
          <rect
            width='100%'
            height='100%'
            strokeWidth={0}
            fill='url(#55d3d46d-692e-45f2-becd-d8bdc9344f45)'
          />
        </svg>
        <div className='relative'>
          <div
            className='absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl'
            aria-hidden='true'
          >
            <div
              className='ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-dwred to-dwlightblue'
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div
            className='absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end'
            aria-hidden='true'
          >
            <div
              className='ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-dwred to-dwlightblue xl:ml-0 xl:mr-[calc(50%-12rem)]'
              style={{
                clipPath:
                  'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
              }}
            />
          </div>
          <div className='mx-auto max-w-7xl px-6 lg:px-8 '>
            {/*<div className='mx-auto max-w-xl sm:text-center'>*/}
            {/*  <h2 className='text-lg font-semibold leading-8 tracking-tight text-dwdarkblue'>*/}
            {/*    Testimonials*/}
            {/*  </h2>*/}
            {/*  <p className='mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'>*/}
            {/*    We have worked with thousands of amazing people*/}
            {/*  </p>*/}
            {/*</div>*/}
            <div className='mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4'>
              <figure className='col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1'>
                <blockquote className='p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900'>
                  <p>{`“${featuredTestimonial.body}”`}</p>
                </blockquote>
                <figcaption className='flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4'>
                  <img
                    className='h-10 w-10 flex-none rounded-full bg-gray-50'
                    src={featuredTestimonial.author.imageUrl}
                    alt=''
                  />
                  <div className='flex-auto'>
                    <div className='font-semibold'>{featuredTestimonial.author.name}</div>
                    {/*<div className='text-gray-600'>{`@${featuredTestimonial.author.handle}`}</div>*/}
                  </div>
                  <div className='hover:underline'>
                    <a href='/case-studies'>Read the case study</a>
                  </div>
                  <img
                    className='h-10 w-auto flex-none'
                    src={featuredTestimonial.author.logoUrl}
                    alt=''
                  />
                </figcaption>
              </figure>
              {/*{testimonials.map((columnGroup, columnGroupIdx) => (*/}
              {/*  <div key={columnGroupIdx} className='space-y-8 xl:contents xl:space-y-0'>*/}
              {/*    {columnGroup.map((column, columnIdx) => (*/}
              {/*      <div*/}
              {/*        key={columnIdx}*/}
              {/*        className={classNames(*/}
              {/*          (columnGroupIdx === 0 && columnIdx === 0) ||*/}
              {/*            (columnGroupIdx === testimonials.length - 1 &&*/}
              {/*              columnIdx === columnGroup.length - 1)*/}
              {/*            ? 'xl:row-span-2'*/}
              {/*            : 'xl:row-start-1',*/}
              {/*          'space-y-8',*/}
              {/*        )}*/}
              {/*      >*/}
              {/*        {column.map((testimonial) => (*/}
              {/*          <figure*/}
              {/*            key={testimonial.author.handle}*/}
              {/*            className='rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5'*/}
              {/*          >*/}
              {/*            <blockquote className='text-gray-900'>*/}
              {/*              <p>{`“${testimonial.body}”`}</p>*/}
              {/*            </blockquote>*/}
              {/*            <figcaption className='mt-6 flex items-center gap-x-4'>*/}
              {/*              <img*/}
              {/*                className='h-10 w-10 rounded-full bg-gray-50'*/}
              {/*                src={testimonial.author.imageUrl}*/}
              {/*                alt=''*/}
              {/*              />*/}
              {/*              <div>*/}
              {/*                <div className='font-semibold'>{testimonial.author.name}</div>*/}
              {/*                <div className='text-gray-600'>{`@${testimonial.author.handle}`}</div>*/}
              {/*              </div>*/}
              {/*            </figcaption>*/}
              {/*          </figure>*/}
              {/*        ))}*/}
              {/*      </div>*/}
              {/*    ))}*/}
              {/*  </div>*/}
              {/*))}*/}
            </div>
          </div>
        </div>
      </div>

      {/* Newsletter section */}
      <div className='mx-auto mt-16 max-w-7xl sm:mt-24 sm:px-6 lg:px-8'>
        <div className='relative isolate overflow-hidden bg-dwdarkblue px-6 py-24 shadow-2xl sm:rounded-3xl sm:px-24 xl:py-32'>
          <h2 className='mx-auto max-w-3xl text-center text-3xl font-bold tracking-tight text-white sm:text-4xl'>
            Curious? We can help you get started.
          </h2>
          <p className='mx-auto mt-2 max-w-xl text-center text-lg leading-8 text-gray-300'>
            <a
              className='hover:underline cursor-pointer font-semibold'
              href='https://auth.app.dagworks.io/signup'
              target='_blank'
              rel='noreferrer'
            >
              Poke around for free
            </a>
            . Then, book some time with our team to learn how Hamilton and DAGWorks can help you
            build and iterate faster. We will help you onboard.
          </p>
          <form
            className='mx-auto mt-10 flex sm:flex-row flex-col gap-x-4 gap-y-2 max-w-md '
            action='https://calendly.com/stefan-dagworks/15-minute-intro-call-with-stefan'
          >
            <label htmlFor='email-address' className='sr-only'>
              Email address
            </label>
            <input
              id='email-address'
              name='email'
              type='email'
              autoComplete='email'
              required
              className='min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-white sm:text-sm sm:leading-6'
              placeholder='Enter your email'
            />
            <button
              type='submit'
              className='flex-none rounded-md bg-white/90 px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white'
            >
              Book some time
            </button>
          </form>
          <svg
            viewBox='0 0 1024 1024'
            className='absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2'
            aria-hidden='true'
          >
            <circle
              cx={512}
              cy={512}
              r={512}
              fill='url(#759c1415-0410-454c-8f7c-9a820de03641)'
              fillOpacity='0.7'
            />
            <defs>
              <radialGradient
                id='759c1415-0410-454c-8f7c-9a820de03641'
                cx={0}
                cy={0}
                r={1}
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(512 512) rotate(90) scale(512)'
              >
                <stop stopColor='rgb(66,157,188)' />
                <stop offset={1} stopColor='rgb(234,85,86)' stopOpacity={0} />
              </radialGradient>
            </defs>
          </svg>
        </div>
      </div>
    </main>
  )
}
