import SyntaxHighlighter from 'react-syntax-highlighter'
const codeString = `import model_training, model_inference

# minimal change from OS Tracker:
tracker = adapters.DAGWorksTracker( 
    project_id=32,
    api_key=os.environ["DW_API_KEY"], 
    username="elijah@dagworks.io", 
    dag_name="my_model_training_dag",
    tags={"env" : "prod"})
dr = (
  driver.Builder()
   .with_config({"data_source" : "./features.csv"})
   .with_modules(model_training, model_inference)
   .with_adapters(tracker) # <--- bingo!
   .build()
)
`
export const SwapOutDriver = () => {
  return (
    <div className='text-md bg-dwdarkblue rounded-md p-3 max-w-full sm:text-lg shadow-sm'>
      <SyntaxHighlighter
        language='python'
        wrapLongLines
        customStyle={{
          backgroundColor: 'transparent',
          filter: 'invert(1.0) brightness(1.2)',
          // width: '100%',
          // height: '100%',
          wordWrap: 'break-word',
          color: 'black', // inverted -- this makes it white...
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  )
}
