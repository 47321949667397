import React from 'react'

class Burr extends React.Component {
  render() {
    return (
      <main>
        <div className='pt-20 flex flex-col md:px-10 md:flex-row items-center justify-center gap-6'>
          <div className='py-4'>
            <div className='py-8'>
              <h1 className='pt-20 text-3xl font-semibold tracking-tight text-gray-900 sm:text-6xl pb-10 '>
                Burr OS & Burr Cloud
              </h1>
              <div className='lead-xl font-light pt-10 text-2xl sm:text-lg'>
                Build stateful applications &amp; agents and watch them think. <br />
                Choose self-hosted or cloud.
                <br />
                <br />
                <a
                  href='https://calendly.com/stefan-dagworks/15-minute-intro-call-with-stefan'
                  className='text-md sm:text-lg font-semibold leading-6 text-gray-900 hover:text-dwlightblue'
                  target='_blank'
                  rel='noreferrer'
                >
                  Want to know more? Schedule time with us <span aria-hidden='true'>→</span>
                </a>
              </div>
            </div>
          </div>
          <div className=''>
            <img
              src='/burr_ui.gif'
              width='500px'
              alt='Burr OS UI'
              className='rounded-t-xl hover:scale-105'
            />
            <div className='px-9 pt-10 pb-14 bg-dwred/90 hover:bg-dwred rounded-b-lg'>
              <div className='text-white space-y-4'>
                <h3 className='text-xl font-bold lead-xl bold'>Burr open source (self-hosted)</h3>
                <div className='text-lg font-light'>
                  Build stateful applications &amp; agents and watch them think:
                </div>
              </div>
              <div className='flex justify-between pt-8'>
                <ul className='flex flex-col gap-y-2.5'>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Lightweight Python</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Runs anywhere python runs</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Telemetry UI</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Pluggable Persistence</span>
                  </li>
                </ul>
                <div className='flex flex-col justify-end'>
                  <a
                    href='https://burr.dagworks.io'
                    className='py-3 px-4 bg-white text-primary-200 paragraph-m rounded-md'
                  >
                    Get started
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            <img
              src='/burr_cloud.png'
              alt='Burr Cloud'
              className='rounded-t-xl hover:scale-105'
              width='500px'
            />
            <div className='px-9 pt-10 pb-14 bg-dwlightblue/90 hover:bg-dwlightblue rounded-b-lg'>
              <div className='text-white space-y-4'>
                <h3 className='text-xl font-bold lead-xl bold'>Burr Cloud</h3>
                <div className='text-lg font-light'>Hosted Burr:</div>
              </div>
              <div className='flex justify-between pt-8'>
                <ul className='flex flex-col gap-y-2.5'>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Everything in Burr self-hosted</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Hosted Execution</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>State Management & Persistence</span>
                  </li>
                  <li className='flex space-x-3 text-white'>
                    <input type='checkbox' className='w-5 h-5' defaultChecked disabled />
                    <span className='paragraph-l font-bold'>Observability</span>
                  </li>
                </ul>
                <div className='flex flex-col justify-end'>
                  <a
                    href='https://forms.gle/w9u2QKcPrztApRedA'
                    className='py-3 px-4 bg-white text-primary-200 paragraph-m  rounded-md'
                  >
                    Sign-up
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

export default Burr
