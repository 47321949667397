import React from 'react'

export const TermsOfUse = () => {
  return (
    <div>
      <div className='flex space-x-4 max-w-fit text-base mx-32'>
        <div>
          <h1 className='text-4xl text-center my-2'>
            <span className=''>DAGWorks Terms of Use</span>
          </h1>
          <p className='text-xl my-8'>
            <span className=''>
              This DAGWorks Platform is made available to you by DAGWorks, Inc.
              (&quot;DAGWorks&quot;). By accessing or using the DAGWorks Platform, you (as the
              individual, company or legal entity that will be using the DAGWorks Platform) agree to
              be bound by these Terms of Use (the &quot;Terms&quot;). If you are using the DAGWorks
              Platform on behalf of a company or other legal entity, you are agreeing to these Terms
              for that entity or organization and representing to DAGWorks that you have the
              authority to bind that entity or organization to these Terms.
            </span>
          </p>

          <ol className='text-xl list-disc list-inside'>
            <li className='my-2'>
              <span className=''>
                Right to Use. DAGWorks grants you a limited, revocable, non-transferable,
                non-sublicensable, royalty-free, right to use the DAGWorks Platform solely to test
                the DAGWorks Platform with your own code and data, provided you: do so in accordance
                with all applicable laws, rules and regulations; only upload non-production data to
                the DAGWorks Platform and, in particular, do not upload any data containing personal
                information, personal data, or personally identifiable information (as defined under
                any US or non-US privacy law or regulation), or confidential or otherwise sensitive
                information; do not use the DAGWorks Platform in any production environment or for
                providing services to any third party do not circumvent, remove, alter, deactivate,
                degrade or thwart any of the security protections or usage limitations in the
                DAGWorks Platform; do not use any robot, spider, scraper or other unauthorized
                automated means to access the DAGWorks Platform; do not decompile, reverse engineer
                or disassemble any software or other products or processes accessible through the
                DAGWorks Platform; do not insert any code or product or manipulate the code of the
                DAGWorks Platform in any way designed to cause harm to DAGWorks or third party
                systems; do not use the DAGWorks Platform for any unauthorized data mining, data
                gathering or extraction method; do not upload, send or transmit any material
                designed to interrupt, destroy or limit the functionality of any computer software
                or hardware connected to or associated with the DAGWorks Platform, including any
                software viruses or any other computer code, files or programs; and do not upload,
                send or transmit any material that (i) infringes any intellectual property or other
                proprietary rights of any party; (ii) you do not have a right to upload, send or
                transmit under any law or under contractual or fiduciary relationships; (iii)
                constitutes unsolicited or unauthorized advertising, promotional materials,
                commercial activities and/or sales, &quot;junk mail,&quot; &quot;spam,&quot;
                &quot;chain letters,&quot; &quot;pyramid schemes,&quot; &quot;contests,&quot;
                &quot;sweepstakes,&quot; or any other form of solicitation; (iv) is unlawful,
                harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory,
                vulgar, obscene, pornographic, libelous, invasive of another&apos;s privacy,
                hateful, discriminatory, or otherwise objectionable; or (v) in the sole judgment of
                DAGWorks, is objectionable or which restricts or inhibits any 1/2 other person from
                using or enjoying the DAGWorks Platform, or which may expose DAGWorks or its users
                to any harm or liability of any type.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                License. As long as you have an account and access to the DAGWorks Platform, you
                hereby grant DAGWorks a non-exclusive, worldwide, royalty-free, fully paid-up
                license to the data you upload to the DAGWorks Platform to provide you with a test
                environment to allow you to test the DAGWorks Platform.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Security. You agree not to access or use the DAGWorks Platform in any manner that
                could damage, disable, overburden, or impair any accounts, computer systems or
                networks. You agree not to attempt to gain unauthorized access to any parts of the
                DAGWorks Platform or any accounts, computer systems or networks. Access to and use
                of password protected or secure areas of the DAGWorks Platform are restricted to
                authorized users only. Each user is required to have a separate account and you
                agree not to share your password(s), account information or access to the DAGWorks
                Platform with any other person. You are responsible for maintaining the
                confidentiality of any account information, and you are responsible for all
                activities that occur through the use of your password(s) or account(s) or as a
                result of your access to the DAGWorks Platform. You agree to notify DAGWorks
                immediately (by email at hello@dagworks.io) of any use of your account(s) that you
                did not authorize or that is not authorized by these Terms.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Feedback. We welcome feedback, comments and suggestions for improvements to the
                DAGWorks Platform (&quot;Feedback&quot;). By submitting Feedback, you grant to
                DAGWorks a non-exclusive, transferable, worldwide, perpetual, irrevocable,
                fully-paid, royalty-free license, with the right to sublicense, under all
                intellectual property rights that you own or control, to use, copy, modify, create
                derivative works based upon and otherwise exploit the Feedback for any purpose
                without any obligations to you.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Privacy. Please refer to our Privacy Policy for information on how we collect, use,
                and disclose information from our users.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Confidentiality. The DAGWorks Platform contains information that is not already a
                matter of public knowledge (&quot;Confidential Information&quot;). You agree to hold
                all Confidential Information in confidence and not to divulge to any third person
                any Confidential Information. You agree to protect the Confidential Information
                using the same degree of care to prevent the unauthorized, use, dissemination or
                publication of the Confidential Information as you use to protect your own
                confidential information but in no event less than a reasonable degree of care. You
                also agree to use the Confidential Information only as needed to facilitate your
                work in creating content or other materials for use by DAGWorks.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Changes to Terms or DAGWorks Platform. We may update the Terms and change or
                discontinue all or any part of the DAGWorks Platform and remove and discard any
                content within the DAGWorks Platform at any time. We may also suspend or terminate
                your access to the DAGWorks Platform services at any time 2/2 without notice to you
                for any reason, at which point all data stored inside that DAGWorks Platform
                instance is deleted permanently. If you require an extension past the defined
                limited time of more than two weeks, you may request an extension by emailing
                hello@dagworks.io, and the granting of such extension is subject to DAGWorks’
                approval in its sole discretion. If you continue to use the DAGWorks Platform after
                we have posted updated Terms, you agree to be bound by the updated Terms. The
                Feedback, Confidentiality, Warranties and Liability and General sections of these
                Terms will survive any termination, discontinuation or cancellation of the DAGWorks
                Platform or your account.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                Warranties and Liability. YOUR USE OF THE DAGWorks Platform IS AT YOUR SOLE RISK.
                THE DAGWorks Platform IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS
                AVAILABLE&quot; BASIS. THE DAGWorks Platform, AND ALL DAGWorks Platform PROVIDED ON
                THE SITE, ARE PROVIDED WITHOUT WARRANTY OF ANY KIND, IMPLIED OR STATUTORY. WITHOUT
                LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
                MERCHANTABILITY, DATA SECURITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, QUIET
                ENJOYMENT AND NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING
                OR USAGE OF TRADE. DAGWORKS MAKES NO WARRANTY THAT (A) THE DAGWORKS PLATFORM WILL
                MEET YOUR REQUIREMENTS; (B) THE DAGWORKS PLATFORM WILL BE UNINTERRUPTED, TIMELY,
                SECURE, OR ERROR-FREE; (C) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
                DAGWORKS PLATFORM WILL BE ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY PRODUCTS,
                SERVICES, INFORMATION, OR OTHER MATERIAL OBTAINED BY YOU THROUGH THE DAGWORKS
                PLATFORM WILL MEET YOUR EXPECTATIONS. DAGWorks will not be liable to you under any
                theory of liability for any indirect, incidental, special, consequential, punitive,
                exemplary or other damages arising out of the DAGWorks Platform or any DAGWorks
                Platform or your use thereof, including loss of profits and damages for loss of
                goodwill, use, or data or other intangible losses, whether based on contract, tort,
                negligence, strict liability, or otherwise, resulting from: (a) the use or the
                inability to use the DAGWorks Platform; (b) the cost of procurement of substitute
                goods and services resulting from any goods, data, information, or services
                purchased or obtained or messages received or transactions entered into through or
                from the DAGWorks Platform; (c) unauthorized access to or alteration of your
                transmissions or data; or (d) any other matter relating to the DAGWorks Platform. In
                no event will DAGWorks’ total liability to you for all damages, losses, or causes of
                action exceed the amount you have paid DAGWorks in the last six (6) months, or, if
                greater, one hundred dollars ($100). This limitation shall apply even if DAGWorks
                was advised of, or should have been aware of, the possibility of such damages. Some
                jurisdictions do not allow certain exclusions or limitations; in such jurisdictions,
                the exclusions and limitations stated in these Terms shall apply to you to the
                maximum extent permitted by law. If you are a user from New Jersey, this Section 8
                is intended to be only as broad as is permitted under the laws of the State of New
                Jersey. If any portion of this section is held to be invalid under the laws of the
                State of New Jersey, the invalidity of such portion will not affect the validity of
                the remaining portions of the applicable sections.
              </span>
            </li>
            <li className='my-2'>
              <span className=''>
                General. You may not use or otherwise export or re-export the DAGWorks Platform
                except as authorized by applicable United States law and the laws of the
                jurisdiction in which the Software was legally obtained or authorized by DAGWorks.
                In particular, but without limitation, the DAGWorks Platform may not be exported or
                re- exported (a) into (or to a national or resident of) any U.S. embargoed countries
                or (b) to anyone on the U.S. Treasury Department&apos;s list of Specially Designated
                Nationals or the U.S. Department of Commerce Denied Person&apos;s List or Entity
                List. By using the DAGWorks Platform, you represent and warrant that you are not
                located in any such country or on any such list. You may not assign the rights
                granted in these Terms, in whole or in part and whether by operation of contract,
                law or otherwise, without DAGWorks’s express written consent. DAGWorks may freely
                assign these Terms to a third party. DAGWorks may audit your use of the DAGWorks
                Platform. These Terms will be governed by and construed in accordance with the laws
                of the state of Delaware, U.S.A., without regard to conflict of laws provisions. If
                any provision of these Terms is found partly or wholly illegal or unenforceable,
                that provision will be enforced to the maximum extent permissible and remaining
                provisions of these Terms will remain in full force and effect. A waiver of any
                breach or default under these Terms will not constitute a waiver of any other
                subsequent breach or default. These Terms represent the complete and exclusive
                agreement between you and DAGWorks relating to the DAGWorks Platform and supersede
                any previous or contemporaneous oral or written communications between you and
                DAGWorks related to the DAGWorks Platform.
              </span>
            </li>
          </ol>
        </div>
      </div>
    </div>
  )
}
