import './App.css'
/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { Home } from './Home'
import About from './About'
import { Pricing } from './Pricing'
import { TermsOfUse } from './TermsOfUse'
import { Privacy } from './Privacy'
import CaseStudies from './CaseStudies'
import { Hamilton } from './Hamilton'
import Burr from './Burr'

const navigation = [
  { name: 'Case Studies', href: '/case-studies', newWindow: false },
  { name: 'Blog', href: 'https://blog.dagworks.io', newWindow: true },
  { name: 'Pricing', href: '/pricing', newWindow: false },
  { name: 'Hamilton OS', href: 'https://www.tryhamilton.dev/', newWindow: true },
  { name: 'Burr OS', href: 'https://github.com/dagworks-inc/burr', newWindow: true },
  { name: 'Docs', href: 'https://docs.dagworks.io', newWindow: true },
  { name: 'About', href: '/about', newWindow: false },
]

const footerNavigation = {
  openSource: [
    { name: 'Hamilton OS', href: 'https://github.com/dagworks-inc/hamilton' },
    { name: 'Burr OS', href: 'https://github.com/dagworks-inc/burr' },
    { name: 'Hamilton Documentation', href: 'https://hamilton.dagworks.io' },
    { name: 'Burr Documentation', href: 'https://burr.dagworks.io' },
    { name: 'Hacker News', href: 'https://news.ycombinator.com/item?id=35056903' },
    {
      name: 'Blog',
      href: 'https://blog.dagworks.io',
    },
  ],
  support: [
    { name: 'Pricing', href: '/pricing' },
    { name: 'Platform Documentation', href: 'https://docs.dagworks.io' },
    { name: 'Contact', href: 'mailto:support@dagworks.io' },
    // { name: 'Guides', href: '#' },
    // { name: 'API Status', href: '#' },
  ],
  platform: [
    // { name: 'Solutions', href: '/solutions' },
    { name: 'Case Studies', href: '/case-studies' },
    { name: 'Documentation', href: 'https://docs.dagworks.io' },
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Blog', href: 'https://blog.dagworks.io' },
    // { name: 'Jobs', href: '#' },
    // { name: 'Press', href: '#' },
    // { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Privacy', href: '/privacy' },
    { name: 'Terms', href: '/terms-of-use' },
  ],
  social: [
    // {
    //   name: 'Facebook',
    //   href: '#',
    //   icon: (props: any) => (
    //     <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
    //       <path
    //         fillRule='evenodd'
    //         d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
    //         clipRule='evenodd'
    //       />
    //     </svg>
    //   ),
    // },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/dagworks.inc',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: 'https://www.twitter.com/dagworks',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path d='M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84' />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: 'https://github.com/dagworks-inc/',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
    // {
    //   name: 'YouTube',
    //   href: '#',
    //   icon: (props: any) => (
    //     <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
    //       <path
    //         fillRule='evenodd'
    //         d='M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z'
    //         clipRule='evenodd'
    //       />
    //     </svg>
    //   ),
    // },
  ],
}

export const App = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  return (
    <div className='bg-white'>
      {/* Header */}
      <header className='absolute inset-x-0 top-0 z-50'>
        <div className='flex flex-row w-screen justify-center items-center'>
          <nav
            className='flex items-center justify-between p-6 lg:px-8 w-full lg:max-w-7xl'
            aria-label='Global'
          >
            <div className='flex lg:flex-1'>
              <a href='/' className='-m-1.5 p-1.5'>
                <span className='sr-only'>DAGWorks</span>
                {/*<img className='h-8 w-auto' src='/logo-with-text-inverted.svg' alt='Logo' />*/}
                <img className='h-8 w-auto' src='/dagworks_image.png' alt='Logo' />
              </a>
            </div>
            <div className='flex lg:hidden'>
              <button
                type='button'
                className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className='sr-only'>Open main menu</span>
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
            <div className='hidden lg:flex flex-row justify-end gap-12 items-center'>
              <div className='hidden lg:flex lg:gap-x-12'>
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className='text-lg font-semibold leading-6 text-gray-900 hover:text-gray-600'
                    target={item.newWindow ? '_blank' : '_self'}
                    rel={item.newWindow ? 'noreferrer' : undefined}
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className='hidden lg:flex lg:flex-1 justify-end'>
                <button
                  type='button'
                  className='inline-flex items-center rounded-md bg-dwlightblue px-3 py-2
               font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-dwlightblue/80'
                >
                  <a
                    href='https://auth.app.dagworks.io/login'
                    rel='noreferrer'
                    target='_blank'
                    className='text-lg font-semibold leading-6 text-white'
                  >
                    Log in <span aria-hidden='true'>&rarr;</span>
                  </a>
                </button>
              </div>
            </div>
          </nav>
          <Dialog as='div' className='lg:hidden' open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className='fixed inset-0 z-50' />
            <Dialog.Panel className='fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
              <div className='flex items-center justify-between'>
                <a href='/' className='-m-1.5 p-1.5'>
                  <span className='sr-only'>DAGWorks</span>
                  <img className='h-8 w-auto' src='/logo.png' alt='' />
                </a>
                <button
                  type='button'
                  className='-m-2.5 rounded-md p-2.5 text-gray-700'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className='sr-only'>Close menu</span>
                  <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                </button>
              </div>
              <div className='mt-6 flow-root'>
                <div className='-my-6 divide-y divide-gray-500/10'>
                  <div className='space-y-2 py-6'>
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className='py-6'>
                    <a
                      href='#'
                      className='-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                    >
                      Log in
                    </a>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
      </header>
      <div className='relative isolate p-14'>
        <svg
          className='absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='83fd4e5a-9d52-42fc-97b6-718e5d7ee527'
              width={200}
              height={200}
              x='50%'
              y={-1}
              patternUnits='userSpaceOnUse'
            >
              <path d='M100 200V.5M.5 .5H200' fill='none' />
            </pattern>
          </defs>
          <svg x='50%' y={-1} className='overflow-visible fill-gray-50'>
            <path
              d='M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z'
              strokeWidth={0}
            />
          </svg>
          <rect
            width='100%'
            height='100%'
            strokeWidth={0}
            fill='url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)'
          />
        </svg>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/hamilton' element={<Hamilton />} />
            <Route path='/burr' element={<Burr />} />
            <Route path='/about' element={<About />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/case-studies' element={<CaseStudies />} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </BrowserRouter>
      </div>

      {/* Footer */}
      <footer className='bg-dwdarkblue' aria-labelledby='footer-heading'>
        <h2 id='footer-heading' className='sr-only'>
          Footer
        </h2>
        <div className='mx-auto max-w-7xl px-6 pb-8 sm:pt-10 lg:px-8'>
          <div className='xl:grid xl:grid-cols-3f xl:gap-8'>
            <div className='grid grid-cols-3'>
              <div>
                <img className='h-7' src='/logo-with-text.png' alt='DAGWorks, Inc.' />
              </div>
              <div>
                <img className='h-14' src='/soc-logo.png' alt='SOC-in-progress' />
              </div>
              <div>
                <img className='h-14' src='/hipaa-logo.png' alt='HIPAA-in-progress' />
              </div>
            </div>
            <div className='mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0'>
              <div className='md:grid md:grid-cols-3 md:gap-8'>
                <div>
                  <h3 className='text-sm font-semibold leading-6 text-white'>Open Source</h3>
                  <ul role='list' className='mt-6 space-y-4'>
                    {footerNavigation.openSource.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className='text-sm leading-6 text-gray-300 hover:text-white'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                  <h3 className='text-sm font-semibold leading-6 text-white'>Platform</h3>
                  <ul role='list' className='mt-6 space-y-4'>
                    {footerNavigation.platform.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className='text-sm leading-6 text-gray-300 hover:text-white'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='mt-10 md:mt-0'>
                  <h3 className='text-sm font-semibold leading-6 text-white'>Support</h3>
                  <ul role='list' className='mt-6 space-y-4'>
                    {footerNavigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className='text-sm leading-6 text-gray-300 hover:text-white'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className='md:grid md:grid-cols-2 md:gap-8'>
                <div>
                  <h3 className='text-sm font-semibold leading-6 text-white'>Company</h3>
                  <ul role='list' className='mt-6 space-y-4'>
                    {footerNavigation.company.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className='text-sm leading-6 text-gray-300 hover:text-white'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className='mt-10 md:mt-0'>
                  <h3 className='text-sm font-semibold leading-6 text-white'>Legal</h3>
                  <ul role='list' className='mt-6 space-y-4'>
                    {footerNavigation.legal.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className='text-sm leading-6 text-gray-300 hover:text-white'
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between'>
            <div>
              <h3 className='text-sm font-semibold leading-6 text-white'>
                Subscribe to our substack
              </h3>
              <p className='mt-2 text-sm leading-6 text-gray-300'>
                The latest news, articles, and resources, sent to your inbox.
              </p>
            </div>
            <form
              className='mt-6 sm:flex sm:max-w-md lg:mt-0'
              action='https://dagworks.substack.com/subscribe'
            >
              <label htmlFor='email-address' className='sr-only'>
                Email address
              </label>
              <input
                type='email'
                name='email-address'
                id='email-address'
                autoComplete='email'
                required
                className='w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-dwdarkblue sm:w-56 sm:text-sm sm:leading-6'
                placeholder='Enter your email'
              />
              <div className='mt-4 sm:ml-4 sm:mt-0 sm:flex-shrink-0'>
                <button
                  type='submit'
                  // onClick={(e) => {}}
                  className='flex w-full items-center justify-center rounded-md bg-white/90 px-3 py-2 text-sm font-semibold text-dwdarkblue shadow-sm hover:bg-dwwhite focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dwdarkblue'
                >
                  Subscribe
                </button>
              </div>
            </form>
          </div>
          <div className='mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between'>
            <div className='flex space-x-6 md:order-2'>
              {footerNavigation.social.map((item) => (
                <a key={item.name} href={item.href} className='text-gray-500 hover:text-gray-400'>
                  <span className='sr-only'>{item.name}</span>
                  <item.icon className='h-6 w-6' aria-hidden='true' />
                </a>
              ))}
            </div>
            <p className='mt-8 text-xs leading-5 text-gray-400 md:order-1 md:mt-0'>
              &copy; 2023 DAGWorks, Inc. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}
