import { FC, useEffect, useState } from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'

const TIME_TO_CHAR = 4

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

const codeString = `from your_favorite_framework import Series, DataFrame, Model

@extract_columns('feature_1', 'feature_2', 'target')
def raw_data_df(location: str) -> DataFrame:
    """Load raw data and expose it."""
    return _load_data(location)

def feature_3(feature_1: Series, feature_2: Series) -> Series:
    """Declaratively create a new feature."""
    return feature_1 * feature_2

def trained_model(feature_1: Series, ..., target: Series) -> Model:
    """Trains my model"""
    training_df = pd.concat([feature_1, ...], axis=1)
    return new Model().fit(training_df, target)

`

const airflowString = `# run easily within an airflow task 
from airflow.decorators import task
from dagworks import adapters
from hamilton import driver
import feature_engineering

@task()
def training_data():
    dr = (
      driver.Builder()
       .with_config({"location": url}) 
       .with_modules(feature_engineering)
       .with_adapters(adapters.DAGWorksTracker(...))  
       .build() 
    )
    results = dr.execute(["training_set"])
    return save_results(results)
`

const jupyterString = `# use in any environment, including a jupyter notebook
from dagworks import adapters
from hamilton import driver
import train_model

dr = (
  driver.Builder()
    .with_config({"location": saved_training_data}) 
    .with_modules(model_training)
    .with_adapters(adapters.DAGWorksTracker(...))  
    .build()
)
results = dr.execute(["trained_model""])
save_model(results["trained_model"])`

const fastapiString = `# use within a web service
from fastapi import FastAPI
from dagworks import adapters
from hamilton import driver
import inference

app = FastAPI()
model = load_model()
dr = (
  driver.Builder()
    .with_modules(feature_engineering)
    .with_adapters(adapters.DAGWorksTracker(...))  
    .build()
)

@app.post("/predict")
def training_data(data_in: Features) -> Predictions:
    results = dr.execute(["inference"], 
        inputs={
            "data_in": data_in, 
            "model": model})}})
    return results`

const dotdotdotString = `# run wherever python runs
import my_framework
from dagworks import adapters
from hamilton import driver

@my_framework.decorator()
def my_function():
    dr = (
      driver.Builder()
        .with_config({"location": url})
        .with_modules(feature_engineering)
        .with_adapters(adapters.DAGWorksTracker(...))  
        .build()
    )
    results = dr.execute(["training_set"])
    return results

`

// New favorite tool:
// https://arthursonzogni.com/Diagon/#GraphDAG
const visualizeString = `Visual documentation and lineage for free:
\

                        ┌────────────────────┐        
                        │     raw_data_df    │        
                        └┬───────┬──────────┬┘        
                   ┌─────▽┐  ┌───▽─────┐   ┌▽────────┐
                   │target│  │feature_2│   │feature_1│
                   └─────┬┘  └───┬─────┘   └┬──┬─────┘
                         │ ┌─────▽──────────▽─┐│      
                         │ │    feature_3     ││      
                         │ └───────┬──────────┘│      
                        ┌▽─────────▽───────────▽┐     
                        │     trained_model     │     
                        └───────────────────────┘     
`

// const maxCharLength = 72 // TODO -- determine this from the above and use it below
// const numLines = codeString.split('\n').length
// raw_data_df -> target
// raw_data_df -> feature_2
// raw_data_df -> feature_1
// feature_2 -> feature_3
// feature_1 -> feature_3
// feature_1 -> trained_model
// feature_3 -> trained_model
// target -> trained_model
export const ExplainOpenSource: FC = () => {
  const [mode, setMode] = useState('airflow')
  const whichToShow = () => {
    switch (mode) {
      case 'airflow': {
        return <CodeExample codeString={airflowString} key={0}></CodeExample>
        break
      }
      case 'jupyter': {
        return <CodeExample codeString={jupyterString} key={1}></CodeExample>
        break
      }
      case 'fastapi': {
        return <CodeExample codeString={fastapiString} key={2}></CodeExample>
        break
      }
      case '...': {
        return <CodeExample codeString={dotdotdotString} key={3}></CodeExample>
        break
      }
    }
  }
  const modes = ['airflow', 'jupyter', 'fastapi', '...'] // TODO -- sync with the above
  return (
    <div className='sm:flex flex-col justify-between h-[50ch]'>
      <div className='flex flex-col h-full w-full'>
        <>
          <div className='flex flex-row justify-start gap-0 pl-2'>
            {modes.map((item, index) => {
              let rounding = ''
              if (index === 0) {
                rounding += 'rounded-tl-lg'
              }
              if (index === modes.length - 1) {
                rounding += 'rounded-tr-lg'
              }

              return (
                <button
                  className={`${
                    mode === item ? 'bg-dwdarkblue' : 'bg-dwdarkblue/90'
                  }  text-lg font-sans ${rounding}
                   text-slate-100 hover:bg-slate-500 py-4 w-1/4`}
                  key={index}
                  onClick={() => {
                    setMode(item)
                  }}
                >
                  <code>{item}</code>
                </button>
              )
            })}
          </div>
          <div className=' max-w-full sm:min-w-[65ch]  ml-2 rounded-b-lg bg-dwdarkblue  p-3'>
            {whichToShow()}
          </div>
        </>
      </div>
    </div>
  )
}

const CodeExample: FC<{ codeString: string; static?: boolean }> = (props) => {
  // Alright, this isn't perfect
  // But its good enough for now
  // What I need to do is figure out how to set it for classes in customStyle
  return (
    <div
      className={`text-md
                bg-transparent xl:pl-0`}
    >
      <SyntaxHighlighter
        language='python'
        wrapLongLines
        customStyle={{
          backgroundColor: 'transparent',
          filter: 'invert(1.0) brightness(1.2)',
          // width: '100%',
          // height: '100%',
          wordWrap: 'break-word',
          color: 'black', // inverted -- this makes it white...
        }}
      >
        {props.codeString}
      </SyntaxHighlighter>
    </div>
  )
}

export default CodeExample
